<template>
  <div>
    <!-- 查询设置区 -->
    <l-search :is-collapsed="true">
      <l-form-model layout="inline" laba-width="70px">
        <l-form-model-item label="素材分类:">
          <l-select
            v-model="puzzleTypeSelect"
            placeholder="请选择"
            @change="puzzleTypeChange"
          >
            <l-select-option
              v-for="(item) in puzzleTypeSelectArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
        <l-button
          type="default"
          class="c-case-manage_add-buttom"
          @click="addCase()"
        >
          新建案例
        </l-button>
        <l-form-model-item label="主题 :">
          <l-select
            v-model="currentThemeId"
            placeholder="请选择"
            not-found-content="暂无主题"
            @change="showList(1)"
          >
            <l-select-option
              v-for="(item) in themeList"
              :key="item.index"
              :value="item.id"
            >
              {{ item.nameZhcn }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
      </l-form-model>
      <template slot="right">
        <l-button type="primary" @click="reset">重置</l-button>
      </template>
    </l-search>
    <!-- 列表展示区 -->
    <l-table-common :data-source="tableData" @change="showList">
      <l-table-column
        v-if="currentThemeId"
        data-index="index"
        title="序号"
      />
      <l-table-column
        data-index="sno"
        title="案例编号"
      />
      <l-table-column
        data-index="nameZhcn"
        title="案例名字"
      />
      <l-table-column
        data-index="themeName"
        title="所属主题"
      />
      <l-table-column title="案例规格">
        <template
          slot-scope="scope"
        >
          <div>
            {{ scope.pixelMatrixWidth == null ? '0' : scope.pixelMatrixWidth }}(横) X
            {{ scope.pixelMatrixHeight == null ? '0' : scope.pixelMatrixHeight }}(竖)
          </div>
        </template>
      </l-table-column>

      <l-table-column
        data-index="cubeCount"
        title="需要魔方数量"
      />

      <l-table-column
        title="素材分类"
      >
        <template slot-scope="scope">
          <div>
            {{ puzzleTypeStr[scope.puzzleType + ''] || '' }}
          </div>
        </template>
      </l-table-column>

      <l-table-column
        v-if="currentThemeId"
        title="顺序调整"
      >
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-if="$utils.isUp(scope.index, tableData)"
            class="c-case-manage_mobile-buttom"
            @click="gancaseSort(scope.id, 1)"
          >
            上移
          </l-button>
          <l-button
            v-if="$utils.isDown(scope.index, tableData)"
            class="c-case-manage_mobile-buttom"
            @click="gancaseSort(scope.id, 2)"
          >
            下移
          </l-button>
        </template>
      </l-table-column>
      <l-table-column
        data-index="views"
        title="阅读量"
      />
      <l-table-column title="详情">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="caseDetail(scope)"
          >
            <div>
              {{ scope.nameZhcn == '默认' ? '无法操作' : '详情' }}
            </div>
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="删除">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="caseRemove(scope)"
          >
            <div>
              删除
            </div>
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示结束区 -->

    <!-- 新增案例和案例详情弹窗 -->
    <div>
      <l-modal
        v-model="addCaseButtom"
        title="案例信息"
        width="520px"
        @ok="caseAddYes"
        @cancel="caseCancel"
      >
        <div class="c-case-manage_case-bags">
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-tex">素材分类 :</div>
            <l-radio-group
              v-model="puzzleType"
              class="c-case-manage_case-bag-input"
              name="radioGroup"
            >
              <l-radio :value="1">
                拼图
              </l-radio>
              <l-radio :value="2">
                拼字
              </l-radio>
            </l-radio-group>
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">案例编号 :</div>
            <l-input v-model="form.sno" class="c-case-manage_case-bag-input" placeholder="请输入案例编号" />
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">案例名称 :</div>
            <l-input v-model="form.nameZhcn" class="c-case-manage_case-bag-input" placeholder="请输入案例名称" />
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">繁体名称 :</div>
            <l-input v-model="form.nameZhtw" class="c-case-manage_case-bag-input" placeholder="请输入繁体名称" />
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">英文名称 :</div>
            <l-input v-model="form.nameEn" class="c-case-manage_case-bag-input" placeholder="请输入英文名称" />
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">作者名称 :</div>
            <l-input v-model="form.author" class="c-case-manage_case-bag-input" placeholder="不输入则不显示作者" />
          </div>

          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">主题选择 :</div>
            <l-select
              v-model="form.themeId"
              class="c-case-manage_case-bag-select"
              placeholder="请选择主题"
              not-found-content="暂无主题"
            >
              <l-select-option
                v-for="(item) in themeList"
                :key="item.index"
                :value="item.id"
              >
                {{ item.nameZhcn }}
              </l-select-option>
            </l-select>
          </div>
          <div v-if="form.id" class="c-case-manage_case-bag-picture-detail">
            <div class="c-case-manage_case-bag-text-detail">封面原图 :</div>
            <template>
              <a-tooltip class="c-case-manage_case-bag-picture-name-detail">
                <template slot="title">
                  <img class="c-case-manage_case-bag-picture-name-img-detail" :src="fileDataTwo.sourceCoverUrl" />
                </template>
                {{ fileDataTwo.sourceCoverName }}
              </a-tooltip>
            </template>
            <l-button v-if="fileDataTwo.sourceCoverUrl" class="c-case-manage_case-bag-picture-download-detail" @click="downLoadImg(fileDataTwo.sourceCoverUrl)">下载</l-button>
            <div class="c-case-manage_case-bag-upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/gancase/upload"
                accept="image/*"
                :file-list="fileListTwo"
                :multiple="true"
                @change="handleChangeTwo"
              >
                <l-button class="c-case-manage_case-bag-upload-detail"><a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div v-if="form.id" class="c-case-manage_case-bag-picture-detail">
            <div class="c-case-manage_case-bag-text-detail">解析原图 :</div>
            <template>
              <a-tooltip class="c-case-manage_case-bag-picture-name-detail">
                <template slot="title">
                  <img class="c-case-manage_case-bag-picture-name-img-detail" :src="fileData.fileUrl" />
                </template>
                {{ fileData.fileName }}
              </a-tooltip>
            </template>
            <l-button v-if="fileData.fileUrl" class="c-case-manage_case-bag-picture-download-detail" @click="downLoadImg(fileData.fileUrl)">下载</l-button>
            <div class="c-case-manage_case-bag-upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/gancase/parsing"
                accept="image/*"
                :file-list="fileList"
                :multiple="true"
                @change="handleChange"
              >
                <l-button class="c-case-manage_case-bag-upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div v-if="!form.id" class="c-case-manage_case-bag-picture">
            <div class="c-case-manage_case-bag-text">封面原图 :</div>
            <div class="c-case-manage_case-bag-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/gancase/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListTwo"
                :multiple="true"
                @change="handleChangeTwo"
              >
                <l-button class="c-case-manage_case-bag-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div v-if="!form.id" class="c-case-manage_case-bag-picture">
            <div class="c-case-manage_case-bag-text">解析原图 :</div>
            <div class="c-case-manage_case-bag-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/gancase/parsing"
                accept="image/*"
                list-type="picture"
                :file-list="fileList"
                :multiple="true"
                @change="handleChange"
              >
                <l-button class="c-case-manage_case-bag-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="c-case-manage_case-bag">
            <div class="c-case-manage_case-bag-text">解析结果 :</div>
            <div v-if="fileData.width" class="c-case-manage_case-bag-input">{{ `${fileData.width}横×${fileData.height}竖-魔方数量${fileData.width / 3 * fileData.height / 3}` }}</div>
          </div>
        </div>
      </l-modal>
    </div>
    <!-- 新增案例和案例详情弹窗结束 -->
  </div>
</template>

<script>
import { debounce } from '@/utils/utils'// 引入防抖函数
export default {
  name: 'CaseManage',
  data() {
    return {
      addCaseButtom: false, // 新增案例弹跳框是否显示
      detailDatas: {}, // 详情弹跳窗初始数据缓存区
      isSaveDetailData: true, // 判断详情是否点击保存修改的数据
      isid: '', // 删除选中案列的id号
      tableData: { // 展示案例列表数据缓存区
        loading: false,
        total: 0,
        page: 1,
        size: 8,
        list: [], // 存储案例列表数据
      },
      newPage: 1, // 存储当前案例列表页数
      form: { // 新建案例弹窗缓存数据和详情弹跳窗数据缓存区
        nameZhcn: '', // 储存主题列表名字
        sno: '', // 储存主题列表编号
        id: '', // 储存主题列表主键
        author: '', // 作者
        themeId: undefined, // 储存增加窗主题列表选中数据的id
        pixelMatrix: '', // 储存主题上传解析图片key值
        nameEn: '', // 英文名称
        nameZhtw: '', // 繁体名称
        sourceCoverName: '', // 案例封面文件名
        sourceCoverUrl: '', // 案例封面链接
      },
      fileList: [], // 新增和修改解析图片按钮上传路径缓存区
      fileListTwo: [], // 案例封面新增和修改图片按钮上传路径缓存区
      sessionKey: sessionStorage.getItem('sessionKey'), // 设key值导数据进图片数据库
      file: '', // 文件缓存文件名
      fileData: { // 上传缓存图片存放
        fileName: '',
        fileUrl: '',
        width: '',
        height: '',
      },
      fileDataTwo: { // 案例封面上传缓存图片存放
        sourceCoverName: '',
        sourceCoverUrl: '',
      },
      themeList: [], // 储存选择主题列表接口数据
      currentThemeId: '', // 查询时选中主题的id
      puzzleTypeSelect: -1, // 类型（1、拼图 2、拼字）
      puzzleTypeSelectArr: [
        {
          value: -1,
          name: '全部'
        },
        {
          value: 1,
          name: '拼图'
        },
        {
          value: 2,
          name: '拼字'
        },
      ],
      puzzleType: '',
      puzzleTypeStr: {
        '1': '拼图',
        '2': '拼字'
      }
    }
  },
  mounted() {
    this.showList(1)
    this.getThemeList()
  },
  methods: {
    // 素材分类选择
    async puzzleTypeChange() {
      this.getThemeList()
      this.showList(1)
    },
    // 获取主题列表
    async getThemeList() {
      const data = await this.$store.dispatch('themes/themeList', {
        page: 1,
        size: 65535,
        puzzleType: this.puzzleTypeSelect === -1 ? '' : this.puzzleTypeSelect,
      })
      this.themeList = data.list || []
    },
    // 获取案例列表
    async showList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.newPage = page // 存储当前案例列表页数
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('caseManage/caseManageList', {
          page: page,
          size: this.tableData.size,
          themeId: this.currentThemeId,
          puzzleType: this.puzzleTypeSelect === -1 ? '' : this.puzzleTypeSelect,
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || [] // 接口数据渲染进列表展示出页面
        this.tableData.list.forEach((item, index) => { // 给列表增加一列 序列号
          item.index = index + 1
        })
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },

    // 新增案例按钮----------------------
    // 获取上传解析图片的文件数据
    handleChange(info) { // 获取解析原图上传图片的文件数据
      // 下面三行连续代码是限制上传图片的数量
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)// 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
      this.fileList = fileList

      if (info.file.status === 'done') {
        const data = info.file.response && info.file.response.object
        if (!data) { // 如果上传的代码图片不符合要求，终止图片的上传进数据库，并把本地图片缓存清空
          this.$message.error(info.file.response && info.file.response.message)// 提示报错不符合要求的图片
          this.fileList = []// 把本地图片缓存清空
          return
        }
        this.fileData = data
        this.form.parsingKey = data.parsingKey // 把解析原图接口返回的图片唯一值记下（新增案例接口需要的数据）
        this.fileData.fileName = this.$utils.cutFront(this.fileData.fileName)
      }
    },
    // 获取案例封面上传图片的文件数据
    handleChangeTwo(info) {
      // 下面三行连续代码是限制上传图片的数量t
      let fileListTwo = [...info.fileList]
      fileListTwo = fileListTwo.slice(-1)// 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
      this.fileListTwo = fileListTwo

      if (info.file.status === 'done') {
        const data = info.file.response && info.file.response.object
        if (!data) { // 如果上传的代码图片不符合要求，终止图片的上传进数据库，并把本地图片缓存清空
          this.$message.error(info.file.response && info.file.response.message)// 提示报错不符合要求的图片
          this.fileListTwo = []// 把本地图片缓存清空
          return
        }
        this.fileDataTwo = data
        this.form.sourceCoverName = data.sourceCoverName
        this.form.sourceCoverUrl = data.sourceCoverUrl
        this.fileDataTwo.sourceCoverName = this.$utils.cutFront(this.fileDataTwo.sourceCoverName)
      }
    },
    async addCase() {
      this.fileData.width = ''
      this.form = this.$options.data().form
      this.fileList = this.$options.data().fileList
      this.fileListTwo = this.$options.data().fileListTwo
      this.puzzleType = ''
      this.addCaseButtom = true
    },
    // 新增案例按钮结束---------------------------------
    // 重置主题选中数据和重新展示列表数据
    reset() {
      this.currentThemeId = undefined
      this.puzzleTypeSelect = -1
      debounce(this.puzzleTypeChange(), 500)// 使用防抖，防止数据刷新过快页面抖动
    },
    // 顺序调整
    async gancaseSort(id, type) {
      await this.$store.dispatch('caseManage/gancaseSort', {
        id: id,
        type: type
      })
      debounce(this.showList(), 500) // 使用防抖，防止数据刷新过快页面抖动
    },

    // 新增和详情弹跳框中的确认按钮功能------------
    async caseAddYes() {
      if (!this.puzzleType) {
        this.$message.warn('请选择素材分类')
        return
      }
      // 传入的id值为空实现增加功能的确认按钮事件，
      // 传入id不为空实现详情修改功能的确认按钮事件
      await this.$store.dispatch('caseManage/caseManageSave', {
        id: this.form.id,
        nameZhcn: this.form.nameZhcn,
        parsingKey: this.form.parsingKey,
        sno: this.form.sno,
        author: this.form.author,
        themeId: this.form.themeId,
        nameZhtw: this.form.nameZhtw,
        nameEn: this.form.nameEn,
        sourceCoverName: this.form.sourceCoverName,
        sourceCoverUrl: this.form.sourceCoverUrl,
        puzzleType: this.puzzleType,
      })
      this.isSaveDetailData = true // 判断数据是否保存
      this.showList(this.newPage)
      this.addCaseButtom = false
    },
    // 新增和详情弹跳框中的确认按钮功能结束------------
    caseCancel() { // 新增和详情弹窗取消触发该事件
      this.isSaveDetailData = false // 判断数据是否保存
    },

    // 案例详情按钮
    async caseDetail(list) {
      // if (this.isSaveDetailData) { // 展示详情初始数据
      //   this.detailDatas = JSON.parse(JSON.stringify(list))
      // } else { // 展示修改后的数据
      //   list = JSON.parse(JSON.stringify(this.detailDatas))
      // }
      this.fileList = this.$options.data().fileList // 清空弹窗修改按钮图片缓存的数据记录
      this.fileListTwo = this.$options.data().fileListTwo
      this.form = list
      this.fileData = {
        fileName: this.$utils.cutFront(list.sourceImageName),
        fileUrl: list.sourceImageUrl,
        width: list.pixelMatrixWidth,
        height: list.pixelMatrixHeight
      }
      this.fileDataTwo.sourceCoverUrl = list.sourceCoverUrl
      this.fileDataTwo.sourceCoverName = this.$utils.cutFront(list.sourceCoverName)
      this.puzzleType = +list.puzzleType
      this.addCaseButtom = true
    },
    // 下载图片
    downLoadImg(url) {
      this.$store.dispatch('download', { url })
    },
    // 案例详情按钮结束

    // 删除
    caseRemove(scope) {
      this.$confirm({
        title: `是否删除${scope.nameZhcn}`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('caseManage/caseManageRemove', {
            id: scope.id,
          })
          this.showList(1)
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
/* 顶部区域样式  */
.c-case-manage_add-buttom {
  margin: 4px 20px 4px 0;
}
//  主题选择框的样式
.c-search .ant-select{
  width: 160px;
}

/* 新增按钮结束  */
//顺序上下按钮
.c-case-manage_mobile-buttom{
  margin-right: 5px;
}
/* 弹窗样式 */
.c-case-manage_case-bags {
  width: 472px;
  height: 511px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .c-case-manage_case-bag {
    width: 460px;
    height: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .c-case-manage_case-bag-text{
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .c-case-manage_case-bag-input{
      width: 300px;
      height: 30px;
    }
    .c-case-manage_case-bag-select{
      width: 300px;
      height: 30px;
      margin-left: 80px;
    }

  }
  .c-case-manage_case-bag-picture {
    width: 460px;
    height: 115px;
    display: flex;
    margin-top: 5px;
    .c-case-manage_case-bag-text{
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-right: 80px;
    }
    .c-case-manage_case-bag-upload-outer{
       width: 300px;
      height: 105px;
      overflow: hidden;
      .c-case-manage_case-bag-upload{
        width: 80px;
        height: 30px;
      }
    }
  }
  .c-case-manage_case-bag-picture-detail {
    width: 460px;
    height: 80px;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    .c-case-manage_case-bag-text-detail{
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-right: 80px;
    }
    .c-case-manage_case-bag-upload-detail-outer{
        width: 80px;
        height: 80px;
        overflow: hidden;
      .c-case-manage_case-bag-upload-detail{
          width: 80px;
          height: 30px;
          // margin-left: 30px;
       }
    }
    .c-case-manage_case-bag-picture-name-detail{
      width: 80px;
      height: 30px;
      // margin-left: 30px;
      border-radius: 5px;
      line-height: 30px;
      text-align: center;
      overflow: hidden;
      color: #595959;

    }
    .c-case-manage_case-bag-picture-download-detail{
      width: 80px;
      height: 30px;
      // margin-left: 30px;
      border: solid 1px #E9E9E9;
      border-radius: 5px;
      line-height: 25px;
      text-align: center;
      color: #595959;
      overflow: hidden;
    }
  }
}
.c-case-manage_case-bag-picture-name-img-detail {// 这个是特殊弹出窗，必须放在外面
  // width: 80px;
  max-width: 80px;
}
</style>
